export default [
    "אולם",
    "הגברה ותאורה",
    "סלון כלות/ליווי",
    "צלם",
    "אלבום תמונות דיגיטלי",
    "אלבום תמונות דיגיטלי נוסף",
    "קנבסים",
    "אלכוהול + כוסות + בלו",
    "תקליטן - DJ",
    "טבעות נישואין",
    "טיפ לעובדים",
    "מגנטים",
    "חולצות גימיק",
    "שביל לחופה",
    "תשלום לרב",
    "רבנות",
    "צעצועים - גימיקים",
    "אגרת אקו\"ם",
    "קישוטים לרכב",
    "הוצאות קטנות מצטברות",
    "חליפת חתן + נעליים",
    "פרחים לכלה",
    "נעלי כלה",
    "חפלה",
    "עגילים לכלה",
    "בגדים שבת חתן",
    "פנטהאוז",
    "מערכת לידים",
    "הזמנות",
    "ממתקים",
    "אפטר פארטי",
    "אחר"
]
