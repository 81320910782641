
import {defineComponent} from "vue";
import {
    alertController,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem, IonLabel,
    IonList,
    IonTextarea,
    IonTitle,
    IonToolbar,
    modalController
} from "@ionic/vue";
import {arrowForwardOutline, checkmarkOutline} from "ionicons/icons";
import expensesList from "@/static-data/expensesList";
import TextInputWithSuggestions from "@/modals/TextInputWithSuggestions.vue";

const emptyExpense = {
    name: '',
    amount: 0,
    amountPaid: 0,
    notes: '',
}

export default defineComponent({
    name: "EditExpense",
    props: {
        input: {
            required: false,
            default: null
        }
    },
    components: {
        IonHeader,
        IonToolbar,
        IonContent,
        IonTitle,
        IonButtons,
        IonButton,
        IonIcon,
        IonList,
        IonInput,
        IonTextarea,
        IonItem,
        IonLabel
    },
    data(){
        return {
            icons: {
                arrowForwardOutline,
                checkmarkOutline
            },
            expensesList,
            expense: {...emptyExpense},
            originalExpense: {...emptyExpense}
        }
    },
    methods: {
        async showExpensesSuggestions(){
            console.log('show suggestions')
            const modal = await modalController.create({
                component: TextInputWithSuggestions,
                showBackdrop: true,
                backdropDismiss: true,
                cssClass: 'small',
                componentProps: {
                    initialSearch: this.expense.name
                }
            })

            await modal.present()
            const {data, role} = await modal.onDidDismiss()
            if(role === 'save'){
                this.expense.name = data
            }
        },
        async back(){
            const exp = JSON.stringify(this.expense)
            const origExp = JSON.stringify(this.originalExpense)
            if(exp !== origExp){
                const alert = await alertController.create({
                    message: 'לסגור ללא שמירת שינויים ?',
                    buttons: [
                        {
                            text: 'אישור',
                            role: 'confirm'
                        },
                        {
                            text: 'ביטול',
                            role: 'cancel'
                        }
                    ]
                })
                await alert.present()
                const {role} = await alert.onDidDismiss()
                if(role === 'confirm'){
                    await modalController.dismiss(null, 'back')
                }
            }else{
                await modalController.dismiss(null, 'back')
            }
        },
        async save(){
            await modalController.dismiss(this.expense, 'save')
        }
    },
    beforeMount() {
        if(this.input){
            this.expense = Object.assign(this.expense, this.input)
            this.originalExpense = Object.assign(this.originalExpense, this.input)
        }
    }
})
