
import {
    IonButton,
    IonButtons, IonCol,
    IonContent, IonFooter, IonGrid,
    IonHeader,
    IonIcon, IonInput, IonItem,
    IonList, IonListHeader, IonRow,
    IonToolbar,
    modalController
} from '@ionic/vue';
import { defineComponent } from 'vue';
import {arrowForwardOutline, checkmarkOutline} from "ionicons/icons";
import expensesList from "@/static-data/expensesList";

export default defineComponent({
    name: 'TextInputWithSuggestions',
    components: {
        IonContent,
        IonHeader,
        IonToolbar,
        IonIcon,
        IonButton,
        IonButtons,
        IonList,
        IonItem,
        IonListHeader,
        IonInput,
        IonGrid,
        IonRow,
        IonCol,
        IonFooter
    },
    props: {
        initialSearch: { type: String, default: '' },
    },
    data() {
        return {
            icons: {
                checkmarkOutline,
                arrowForwardOutline
            },
            search: '',
            expensesList
        };
    },
    computed: {
        filteredExpensesList(){
            return this.expensesList.filter(exp => exp.includes(this.search))
        }
    },
    methods: {
        back(){
            modalController.dismiss(null, 'back')
        },
        save(option: string){
            modalController.dismiss(option, 'save')
        }
    },
    mounted() {
        if(this.initialSearch){
            this.search = this.initialSearch
        }

        const searchInputRef: any = this.$refs["searchInput"]
        setTimeout(() => {
            searchInputRef.$el.setFocus()
        }, 500)
    }
});
